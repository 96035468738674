export const maxWidthInt = 1770;
export const desktopWidthInt = 1100;
export const tabletWidthInt = 1099;
export const mobileWidthInt = 499;
export const tabletWidthInt2 = 1330;

export const Variables = {
  maxWidth: `${maxWidthInt}px`,
  desktopW: `${desktopWidthInt}px`,
  tabletWidth: `${tabletWidthInt}px`,
  tabletWidth2: `${tabletWidthInt2}px`,
  mobileWidth: `${mobileWidthInt}px`,

  // colors
  // When swapping leave note of what the color looks like
  // used in button link styling
  // primaryColor: '#E91152', // CHECK THIS COLOR OUT #39B5E0 1/28/23
  primaryColor: "green",
  primaryColor2: "#181818",
  secondaryColor: "#333333",
  transparent: "transparent",
  black: "black",
  white: "#ffff",
  $darkGray: "#434242",

  // Type
  sourceSans: "'Source Sans Pro', sans-serif",
  ubuntu: "'Ubuntu', sans-serif",

  companyName: "Moulis Financial",
  logo1Url: "images/cflogo.png",
  logoWhite: "logos/logo-white.png",
  logoBlack: "logos/logo-black.png",
  logoGreen: "logos/logo-green.png",
  background: "/Images/texture_background.jpg",
};
